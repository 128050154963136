<template>
    <div class="home">
        <div class="title">Overview</div>
        <div class="status">
            <div class="grid status-envs">
                <template v-for="env in [{ id: 'int', name: 'Integration' }, { id: 'valid', name: 'Validation' }, { id: 'prod', name: 'Production' }]">
                    <div class="col status-card" :class="'status-' + env.id" :key="env.id">
                        <div class="status-envs-counts">
                            <div class="status-envs-count ok">{{ projects.filter(project => project.status[env.id] === 'ok').length }}</div>
                            <div class="status-envs-count">{{ projects.filter(project => project.status[env.id] === '-' && !(env.id === 'prod' && project.name === 'resort-server' && project.name === 'datalake-copy-functions')).length }}</div>
                            <div class="status-envs-count ko">{{ projects.filter(project => project.status[env.id] === 'ko').length }}</div>
                        </div>
                        <div class="status-card-title">{{ env.name }}</div>
                    </div>
                </template>
            </div>
            <div class="grid status-projects">
                <div class="col-8 status-projects-classic-cloud">
                    <div v-if="!state.cloudIsExpand" class="status-card status-projects-classic" :class="{ expand: state.classicIsExpand }">
                        <div class="status-project-title">
                            <div class="status-card-title">Classic projects</div>
                            <div class="status-projects-search-zone">
                                <Slider v-model="state.nogoOnly" label="NoGo only" textPosition="left" />
                                <div class="status-projects-search">
                                    <img src="@/assets/img/search.svg" alt="search">
                                    <input v-model="state.search">
                                </div>
                            </div>
                            <div class="status-card-title-collapse-expand">
                                <div class="status-card-title-expand" @click="onResizePanel('classic')">
                                    <img :src="expandCollapseIcon" alt="Resize panel">
                                </div>
                            </div>
                        </div>
                        <div class="status-card-content">
                            <table class="status-projects-table" v-if="projects.length > 0">
                                <tr>
                                    <th class="status-project-name">Project</th>
                                    <th class="status-project-tag">GitLab</th>
                                    <th colspan="2" class="status-project-env">Integration</th>
                                    <th colspan="2" class="status-project-env">Validation</th>
                                    <th colspan="2" class="status-project-env">Production</th>
                                    <th></th>
                                </tr>
                                <template v-for="category in ['classic']" class="status-env-category">
                                    <tr v-for="project in projectsSearchFiltered.filter(project => project.category === category)" :key="project.id" class="status-project" :class="getNogoProjectClass(project)">
                                        <td class="status-project-name" :class="{ 'loading' : project.loading != 0}">{{ project.name }}</td>
                                        <td><div class="status-project-tag"><img src="@/assets/img/tag.svg" alt="tag">{{ project.tag }}</div></td>
                                        <td class="status-project-status"><div class="marker" :class="project.status.int"></div></td>
                                        <td><div class="status-project-version">{{ project.version.int }}</div></td>
                                        <td class="status-project-status"><div class="marker" :class="project.status.valid"></div></td>
                                        <td><div class="status-project-version" :class="getProjectVersionClass(project, 'valid')">{{ project.version.valid }}</div></td>
                                        <td class="status-project-status"><div class="marker" :class="project.status.prod" v-if="project.name != 'resort-server'"></div></td>
                                        <td><div class="status-project-version" v-if="project.name != 'resort-server'" :class="getProjectVersionClass(project, 'prod')">{{ project.version.prod }}</div></td>
                                        <td class="status-project-actions">
                                            <div class="status-project-actions-action"><img src="@/assets/img/refresh.svg" alt="refresh" class="refresh" :class="{ 'loading' : project.loading != 0}" @click="refreshProject(project)"></div>
                                            <div class="status-project-actions-action"><img src="@/assets/img/nogo-deliver.svg" alt="ng-deliver" class="deliver" @click="nogoProject(project)"></div>
                                            <div class="status-project-actions-action" v-if="hasPipeline(project && pipelineAllow(project))"><img src="@/assets/img/pipeline.svg" class="pipeline" @click="openLink(project.pipeline.web_url)"></div>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                            <div class="status-projects-loader" v-else>
                                <Loader />
                            </div>
                        </div>
                    </div>
                    <div v-if="!state.classicIsExpand" class="status-card status-projects-cloud" :class="{ expand: state.cloudIsExpand }">
                        <div class="status-project-title">
                            <div class="status-card-title">Cloud projects</div>
                            <div class="status-projects-search-zone">
                                <Slider v-model="state.nogoOnly" label="NoGo only" textPosition="left" />
                                <div class="status-projects-search">
                                    <img src="@/assets/img/search.svg" alt="search">
                                    <input v-model="state.search">
                                </div>
                            </div>
                            <div class="status-card-title-collapse-expand">
                                <div class="status-card-title-expand" @click="onResizePanel('cloud')">
                                    <img :src="expandCollapseIcon" alt="Resize panel">
                                </div>
                            </div>
                        </div>

                        <div class="status-card-content">
                            <table class="status-projects-table" v-if="projects.length > 0">
                                <tr>
                                    <th class="status-project-name">Project</th>
                                    <th class="status-project-tag">GitLab</th>
                                    <th colspan="2" class="status-project-env">Integration</th>
                                    <th colspan="2" class="status-project-env">Validation</th>
                                    <th colspan="2" class="status-project-env">Production</th>
                                    <th></th>
                                </tr>
                                <template v-for="category in ['cloud-cloud', 'cloud-data-collection', 'cloud-data-preparation', 'cloud-data-access', 'cloud-data-visualization', 'cloud-tools']" class="status-env-category">
                                    <tr v-for="project in projectsSearchFiltered.filter(project => project.category === category)" :key="project.id" class="status-project" :class="getNogoProjectClass(project)">
                                        <td class="status-project-name" :class="{ 'loading' : project.loading != 0}">{{ project.name }}</td>
                                        <td><div class="status-project-tag"><img src="@/assets/img/tag.svg" alt="tag">{{ project.tag }}</div></td>
                                        <td class="status-project-status"><div class="marker" :class="project.status.int"></div></td>
                                        <td><div class="status-project-version">{{ project.version.int }}</div></td>
                                        <td class="status-project-status"><div class="marker" :class="project.status.valid"></div></td>
                                        <td><div class="status-project-version" :class="getProjectVersionClass(project, 'valid')">{{ project.version.valid }}</div></td>
                                        <td class="status-project-status"><div class="marker" :class="project.status.prod" v-if="project.name != 'datalake-copy-functions'"></div></td>
                                        <td><div class="status-project-version" v-if="project.name != 'datalake-copy-functions'" :class="getProjectVersionClass(project, 'prod')">{{ project.version.prod }}</div></td>
                                        <td class="status-project-actions">
                                            <div class="status-project-actions-action"><img src="@/assets/img/refresh.svg" alt="refresh" class="refresh" :class="{ 'loading' : project.loading != 0}" @click="refreshProject(project)"></div>
                                            <div class="status-project-actions-action"><img src="@/assets/img/nogo-deliver.svg" alt="ng-deliver" class="deliver" @click="nogoProject(project)"></div>
                                            <div class="status-project-actions-action" v-if="hasPipeline(project) && pipelineAllow(project)"><img src="@/assets/img/pipeline.svg" class="pipeline" @click="openLink(project.pipeline.web_url)"></div>
                                        </td>
                                    </tr>
                                    <tr :key="category" class="status-projects-table-separator">
                                        <td colspan="7"></td>
                                    </tr>
                                </template>
                            </table>
                            <div class="status-projects-loader" v-else>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 status-projects-services">
                    <div class="status-card status-services">
                        <div class="status-card-title">Services</div>
                        <div class="status-card-content">
                            <template v-for="service in services">
                                <div :key="service.name" :href="service.url" class="status-services-link">
                                    <img :src="getServiceIcon(service)" alt="serv-icon" class="status-services-link-icon" :class="service.status">
                                    <span class="status-services-link-title" @click="openLink(service.url)" :title="service.description">{{ service.name }}</span>
                                    <span class="status-services-version">{{ service.version }}</span>
                                    
                                    <span v-if="service.version == '?' || service.versionAvailable == '?'">?</span>
                                    
                                    <span v-else-if="compareVersions(service.version, service.versionAvailable) < 0" class="status-services-version-update status-services-version-update-available">
                                        Update available ({{ service.versionAvailable }})
                                    </span>
                                    
                                    <span v-else class="status-services-version-update">Up-to-date</span>
                                    
                                    <span class="status-services-version-actions">
                                        <img src="@/assets/img/refresh.svg" alt="refresh" class="refresh" :class="{ 'loading' : service.loading != 0}" @click="getServiceStatus(service, true)">
                                    </span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalSimple
            ref="modal-simple-no-go"
            :attach="$root">
            <NoGo
                :project="state.noGoProject"
                :projects="noGoProjects"
                :user="user"
                @valid="onCloseNoGo"
                @cancel="onCloseNoGo" />
        </ModalSimple>
    </div>
</template>

<script>
// Api
import Server from '@/api/server'
// Components
import Loader from '@/components/shared/Loader'
import Slider from '@/components/shared/Slider'
import ModalSimple from '@/components/shared/ModalSimple'
import NoGo from '@/components/noGo/NoGo'

export default {
    name: 'Home',
    components: {
        Loader,
        ModalSimple,
        NoGo,
        Slider
    },
    data() {
        return {
            state: {
                search: "",
                noGoProject: {},
                classicIsExpand: false,
                cloudIsExpand: false,
                nogoOnly: false
            },
            projects: [],
            noGoProjects: [],
            worklistProject: [],
            services: [],
            user: {}
        }
    },
    computed: {
        projectsSearchFiltered() {
            return this.worklistProject.filter((project) => project.name.includes(this.state.search))
        },
        expandCollapseIcon() {
            return (this.state.classicIsExpand || this.state.cloudIsExpand) ? require('@/assets/img/collapse.svg') : require('@/assets/img/expand.svg')
        }
    },
    watch: {
        'state.nogoOnly': {
            handler() {
                this.buildWorklistProject()
            }
        }
    },

    mounted() {
        Server.getAuthUser().then((response) => {
            this.user = response.data
        }).catch((_) => {
            console.log('Can not get user')
        })
        Server.getServices().then((response) => {
            this.services = response.data.map(service => ({
                ...service,
                loading: 0
            }))
        })
        Server.getProjects().then((response) => {
            this.projects = response.data.map(project => ({
                ...project,
                loading: false
            }))
            this.projects.sort((a, b) => a.name.localeCompare(b.name));
            this.projects.forEach(project => {
                if (Object.values(project.status).find(status => status !== "ok")) {
                    this.getProjectStatus(project, false, true)
                }
            })
            this.worklistProject = JSON.parse(JSON.stringify(this.projects))
            this.getNoGoProject()
        })
    },
    methods: {
        buildWorklistProject() {
            if (this.state.nogoOnly) {
                if (this.state.nogoOnly) {
                    this.worklistProject = []
                    this.noGoProjects.forEach((nogo) => {
                        let founded = this.projects.find((pr) => pr.name === nogo.project)
                        if (founded){
                            this.worklistProject.push(founded)
                        }
                    })
                }
            } else {
                this.worklistProject = JSON.parse(JSON.stringify(this.projects))
            }
        },
        onResizePanel(project) {
            if (project === 'classic') {
                this.state.classicIsExpand = !this.state.classicIsExpand
            } else {
                this.state.cloudIsExpand = !this.state.cloudIsExpand
            }
        },
        getNoGoProject() {
            Server.getProjectNoGo().then((result) => {
                this.noGoProjects = result.data
                this.buildWorklistProject()
            })
        },
        refreshProject(project) {
            this.getProjectStatus(project, true)
            Server.getProjectTags(project).then((response) => {
                if (response && response.data && response.data.tags && response.data.tag) {
                    project.tags = response.data.tags
                    project.tag = response.data.tag
                }
            })
        },
        refreshNoGoProject(project) {
            this.getProjectStatus(project, false, true)
        },
        nogoProject(project) {
            this.state.noGoProject = project
            this.$refs['modal-simple-no-go'].show()
        },
        onCloseNoGo(payload) {
            if (payload) {
                // Payload present, valid has been pressed!
                if (payload.nogo) {
                    let payloadNoGo = {
                        nogoMessage: payload.nogoMessage,
                        userName: payload.userName,
                        userDisplayName: payload.userDisplayName,
                        time: payload.time
                    }
                    // NoGo should be enable
                    Server.updateProjectNoGo(payload.project, payloadNoGo)
                } else {
                    // NoGo should be disable
                    Server.updateProjectNoGo(payload.project, null).then( () => {
                        this.refreshNoGoProject(this.state.noGoProject)
                    })
                }
                this.getNoGoProject()
            }
            this.$refs['modal-simple-no-go'].hide()
        },
        getProjectStatusEnv(project, env, force, iteration) {
            project.loading = project.loading + 1
            project.version[env] = (project.version[env] != 'Retrying...') ? "..." : project.version[env]
            project.status[env] = "-"
            Server.getProjectStatusByEnv(project, env, force).then((response) => {
                    project.version[env] = response.data
                    project.status[env] = "ok"
                }).catch(async (error) => {
                        if (error.response.data.includes('502 Bad Gateway')) {
                            project.version[env] = 'Error 502'
                        } else if (error.response.data.startsWith('<html>')) {
                            project.version[env] = 'Unknown error'
                        } else {
                            if (iteration > 3) {
                                project.version[env] = error.response.data
                            } else {
                                project.version[env] = 'Retrying...'
                                await setTimeout(() => this.getProjectStatusEnv(project, env, force, iteration + 1), iteration * 1000)
                            }
                        }
                        project.status[env] = (error.response.data === "Not implemented" || error.response.data === "Version not found") ? "-" : "ko"
                }).finally(() => {
                    project.loading = project.loading - 1
                })
        },
        getProjectStatus(project, force = false, retry = false) {
            let envs = ['int', 'valid', 'prod']
            project.loading = project.loading + 1
            envs.forEach((env) => {
                project.version[env] = "..."
                project.status[env] = "-"
            })
            Server.getProjectStatus(project, force).then((response) => {
                let result
                envs.forEach((env) => {
                    result = response.data[env]
                    project.version[env] = result.data
                    if (result.status == 200) {
                        project.status[env] = "ok"
                    } else {
                        project.status[env] = (result.data === "Not implemented" || result.data === "Version not found") ? "-" : "ko"
                        if (retry) {
                            project.version[env] = 'Retrying...'
                            setTimeout(() => this.getProjectStatusEnv(project, env, force, 1), 1000)
                        }
                    }
                })
            }).finally(() => {
                project.loading = project.loading - 1
            })
        },
        getServiceIcon(service) {
            try {
                return require('../assets/img/' + service.id + '-icon.svg')
            } catch {
                return ''
            }
        },
        getServiceStatus(service, force = false) {
            service.loading++
            Server.getServiceStatus(service).then((_) => {
                service.status = "ok"
            }).catch((_) => {
                service.status = "ko"
            }).finally(() => {
                service.loading--
            })
            service.loading++
            Server.getServiceVersion(service, force).then((responseVersion) => {
                service.version = responseVersion.data
            }).catch((_) => {
                service.version = "?"
            }).finally(() => {
                service.loading--
            })
            service.loading++
            Server.getServiceVersionAvailable(service, force).then((responseVersion) => {
                service.versionAvailable = responseVersion.data
            }).catch((_) => {
                service.versionAvailable = "?"
            }).finally(() => {
                service.loading--
            })
        },
        compareVersions(v1, v2) {
            const parts1 = v1.split('.').map(Number);
            const parts2 = v2.split('.').map(Number);

            for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
                const part1 = parts1[i] || 0;
                const part2 = parts2[i] || 0;

                if (part1 > part2) return 1;
                if (part1 < part2) return -1;
            }
            return 0;
        },
        getProjectVersionClass(project, env) {
            if (project && project.status && project.version && project.status[env] === 'ok' && project.version[env] !== project.tag)
                return 'to-update'
            else return ''
        },
        getNogoProjectClass(project) {
            if (project && this.noGoProjects.find((item) => item.project === project.name)) return 'no-go'
            else return ''
        },
        hasPipeline(project) {
            if ((project.name === 'resort-server') || project.nogo) return false
            return project.pipeline && project.pipeline.web_url && project.status && project.version
                && ((project.version.valid !== project.tag) || (project.name !== 'datalake-copy-functions' && project.version.prod !== project.tag))
        },
        pipelineAllow(project) {
            return !(project && this.noGoProjects.find((item) => item.project === project.name))
        },
        openLink(url) {
            window.open(url)
        }
    }
}
</script>

<style lang="less">

@import (reference) "~@/assets/style/site.less";

.home {
    position: relative;
    height: 100%;
    width: 100%;
    .title {
        font-size: 2em;
        font-weight: bold;
        padding: 30px 20px;
        height: 70px;
    }
    .status {
        height: calc(100% - 70px);
        width: calc(100% - 1rem);
        .status-card {
            height: calc(100% - 50px);
            background-color: #0d0c0f;
            border-radius: 1em;
            margin: 20px;
            padding: 20px;
            .status-project-title {
                display: flex;
                align-items: center;
                .status-projects-search-zone {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    .status-projects-search {
                        flex-grow: 1;
                        margin-left: 20px;
                        border-radius: .5em;
                        background-color: lighten(#16171b, 5%);
                        img {
                            display: inline-block;
                            height: 30px;
                            padding: 5px 8px;
                            vertical-align: bottom;
                            opacity: .2;
                        }
                        input {
                            background-color: transparent;
                            outline: none;
                            line-height: 30px;
                            padding: 0;
                            border: 0;
                            color: white;
                            opacity: .4;
                        }
                    }
                }
                .status-card-title-collapse-expand {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    width: 50px;
                    .status-card-title-expand {
                        display: flex;
                        padding: 5px 10px;
                        border-radius: 0.5em;
                        cursor: pointer;
                        background-color: lighten(#16171b, 5%);
                        &:hover {
                            background-color: lighten(#16171b, 20%);
                        }
                        img {
                            height: 20px;
                        }
                    }
                }
            }
            .status-card-title {
                font-size: 1.3em;
                font-weight: bold;
                flex: 1;
            }
            .status-card-content {
                margin-top: 10px;
                height: calc(100% - 40px);
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(255,255,255,0.15);
                    border-radius: 10px;
                    margin: 20px 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(255,255,255,0.2);
                    border-radius: 10px;
                }
            }
        }
        .status-envs {
            position: relative;
            height: 115px;
            .status-envs-counts {
                float: right;
                .status-envs-count {
                    display: inline-block;
                    font-size: .9em;
                    margin-left: 10px;
                    border-radius: .5em;
                    min-width: 40px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    font-weight: bold;
                    background-color: #fd9304;
                    &.ok {
                        background-color: #01bd4f;
                    }
                    &.ko {
                        background-color: #d22925;
                    }
                }
            }
        }
        .status-projects {
            position: relative;
            height: calc(100% - 100px);
            .status-projects-classic-cloud {
                position: relative;
                height: 100%;
                .status-projects-classic,
                .status-projects-cloud {
                    position: relative;
                    &.expand {
                        height: calc(100% - 20px);
                    }
                }
                .status-projects-classic {
                    padding-bottom: 5px;
                    height: 350px;
                    margin: 0 10px 20px 10px;
                }
                .status-projects-cloud {
                    padding-bottom: 10px;
                    height: calc(100% - 390px);
                    margin: 0 10px 0 10px;
                }
            }
            .status-projects-table {
                border-collapse: collapse;
                margin-top: 10px;
                width: calc(100% - 10px);
                td {
                    height: 30px;
                    vertical-align: middle;
                }
                th {
                    text-align: left;
                    &.status-project-name {
                        min-width: 300px;
                    }
                    &.status-project-tag {
                        min-width: 140px;
                    }
                    &.status-project-env {
                        min-width: 200px;
                    }
                }
                .status-projects-table-separator {
                    td {
                        height: 15px;
                    }
                }
            }
            .status-project {
                color: #979898;
                .status-project-name {
                    padding-right: 50px;
                    &.loading {
                        background-image: linear-gradient(90deg, darken(#979898, 20%) 0%, white 50%, darken(#979898, 20%) 100%);
                        background-size: 200% auto;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        animation: text-loading 2s linear infinite;
                        @keyframes text-loading { from { background-position: 200% center; } }
                    }
                }
                .status-project-status {
                    vertical-align: middle;
                    padding-right: 10px;
                    width: 5px;
                    .marker {
                        height: 20px;
                        width: 4px;
                        border-radius: .5em;
                        background-color: #fd9304;
                        &.ok {
                            background-color: #01bd4f;
                        }
                        &.ko {
                            background-color: #d22925;
                        }
                    }
                }
                .status-project-version {
                    font-size: .8em;
                    background-color: lighten(#0e0e0e, 3%);
                    color: #c9cbcc;
                    border-radius: .5em;
                    padding: 3px 10px;
                    height: 20px;
                    margin-right: 20px;
                    &.to-update {
                        background-color: #0185bd;
                    }
                }
                .status-project-tag {
                    font-size: .8em;
                    color: #c9cbcc;
                    vertical-align: bottom;
                    margin-right: 20px;
                    img {
                        height: 15px;
                        opacity: .6;
                        margin-right: 8px;
                        vertical-align: bottom;
                    }
                }
                .status-project-actions {
                    display: flex;
                    align-items: center;
                    .status-project-actions-action {
                        text-align: center;
                        img {
                            height: 15px;
                            opacity: .3;
                            cursor: pointer;
                            &:hover {
                                opacity: .6;
                            }
                            &.refresh {
                                margin-right: 8px;
                                &.loading {
                                    animation: refresh-loading 2s linear infinite;
                                    @keyframes refresh-loading { 100% { transform: rotate(360deg); }  }
                                }
                            }
                            &.deliver {
                                height: 17px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            .no-go {
                .to-update {
                    background-color: @dark_red !important;
                }
                .deliver {
                    filter: invert(33%) sepia(98%) saturate(7136%) hue-rotate(343deg) brightness(83%) contrast(119%);
                    opacity: .8 !important;
                    &:hover {
                        opacity: 1 !important;
                    }
                }
            }
        }
        .status-services {
            margin: 0 10px 20px 10px;
            height: calc(100% - 20px);
            .status-services-link {
                margin: 15px 0;
                white-space: nowrap;
                .status-services-link-icon {
                    display: inline-block;
                    vertical-align: bottom;
                    height: 35px;
                    min-width: 30px;
                    margin-right: 10px;
                    padding: 8px 0;
                    filter: brightness(0) saturate(100%) invert(58%) sepia(50%) saturate(1800%) hue-rotate(357deg) brightness(101%) contrast(102%);
                    &.ok {
                        filter: brightness(0) saturate(100%) invert(58%) sepia(48%) saturate(3253%) hue-rotate(104deg) brightness(91%) contrast(99%);
                    }
                    &.ko {
                        filter: brightness(0) saturate(100%) invert(28%) sepia(88%) saturate(1528%) hue-rotate(344deg) brightness(90%) contrast(94%);
                    }
                }
                .status-services-link-title,
                .status-services-version,
                .status-services-version-update {
                    display: inline-block;
                    vertical-align: bottom;
                    background-color: lighten(#16171b, 3%);
                    border-radius: .5em;
                    height: 35px;
                    line-height: 35px;
                    padding: 0 15px;
                    color: white;
                    text-decoration: inherit;
                }
                .status-services-link-title {
                    cursor: pointer;
                    width: 130px;
                    &:hover {
                        background-color: lighten(#16171b, 10%);
                    }
                }
                .status-services-version {
                    width: 90px;
                    margin-left: 10px;
                    font-size: .7em;
                    text-align: center;
                }
                .status-services-version-update {
                    width: 190px;
                    margin-left: 10px;
                    font-size: .7em;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.status-services-version-update-available {
                        background-color: #0185bd;
                    }
                }
                .status-services-version-actions {
                    line-height: 30px;
                    margin-left: 10px;
                    img {
                        height: 15px;
                        opacity: .3;
                        cursor: pointer;
                        &:hover {
                            opacity: .6;
                        }
                        &.refresh {
                            &.loading {
                                animation: refresh-loading 2s linear infinite;
                                @keyframes refresh-loading { 100% { transform: rotate(360deg); }  }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .home {
        .title {
            display: none;
        }
        .grid {
            flex-direction: column;
            flex-wrap: initial;
            &.status-envs {
                height: auto;
                margin-bottom: 20px;
                .status-card {
                    margin: 10px 20px 0 20px;
                    padding-bottom: 20px;
                }
            }
            &.status-projects {
                .status-projects-classic-cloud {
                    width: 100%;
                    height: auto;
                }
                .status-projects-services {
                    display: none;
                }
            }
        }
    }
}

</style>
