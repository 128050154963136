<template>
    <div
        v-if="showModal"
        class="modal-simple">
        <div
            class="modal-simple-wrapper">
            <span
                class="modal-simple-close material-icons"
                @click="hide">close</span>
            <div class="modal-simple-content">
                <slot />
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'ModalSimple',
    props: {
        attach: { type: Object, default: null },
        selfClose: { type: Boolean, default: true }
    },
    data() {
        return {
            showModal: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            if ((this.attach) && (this.attach.$el)) {
                this.$el.parentNode.removeChild(this.$el)
                this.attach.$el.appendChild(this.$el)
            }
        })
    },
    methods: {
        show() {
            this.showModal = true
            this.$emit('show')
        },
        hide() {
            if (this.selfClose) {
                this.showModal = false
                this.$emit('close')
            } else {
                this.$emit('close', { selfClose: false })
            }
        }
    }
}
</script>

<style scoped lang="less">

@import (reference) "~@/assets/style/site.less";

.modal-simple {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: fadeout(lighten(#16171b, 20%), 20%);
    .modal-simple-wrapper {
        position: relative;
        max-width: calc(100% - 60px);
        max-height: 90vh;
        margin: 20px;
        padding: 10px 20px;
        background-color: lighten(#16171b, 5%);
        border-radius: calc(0.5em * 2);
        &.mobile {
            max-height: 70vh;
            .modal-simple-content {
                max-height: calc(60vh - 10px);
            }
        }
        .modal-simple-close {
            position: absolute;
            right: -10px;
            top: -25px;
            padding: 10px;
            color: @middle_grey;
            background-color: lighten(#16171b, 10%);
            box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                color: @grey;
            }
        }
        .modal-simple-content {
            position: relative;
            max-height: calc(80vh - 10px);
            width: 100%;
            margin-top: 10px;
            overflow-y: auto;
        }
    }
}

</style>
