<template>
    <div class="slider-global">
        <span v-if="textPosition === 'left'" class="item-update-text left">{{ label }}</span>
        <label class="switch">
            <input type="checkbox" v-model="isChecked">
            <span class="slider round"></span>
        </label>
        <span v-if="textPosition !== 'left'" class="item-update-text right">{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        value: { type: Boolean, default: false },
        textPosition: { type: String, default: 'right' },
        label: { type: String, default: null }
    },
    computed: {
        isChecked: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<style scoped lang="less">

@import (reference) "~@/assets/style/site.less";

.slider-global {
    display: flex;
    align-items: center;
    .item-update-text {
        &.left {
            margin-right: 10px;
        }
        &.right {
            margin-left: 10px;
        }
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 19px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lighten(#16171b, 10%);
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #0185bd;
}
input:focus + .slider {
    box-shadow: 0 0 1px #0185bd;
}
input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

</style>
