<template>
    <div class="main">
        <template v-if="!user">
            <div class="login">
                <div class="login-card">
                    <div class="login-logo">
                        <img src="@/assets/img/logo.png">
                    </div>
                    <div class="login-button" @click="login">
                        <img src="@/assets/img/gitlab-icon.svg">
                        Login with GitLab
                    </div>
                    <Loader class="loader-autologin" v-if="state.autologin" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="main-notification">
                <Notification ref="notification" />
            </div>
            <div class="page">
                <div class="menu">
                    <div class="menu-logo">
                        <img src="@/assets/img/mini-logo.png">
                    </div>
                    <div class="menu-card">
                        <router-link to="/" class="menu-link" title='Home'>
                            <img class="menu-link-icon" src="@/assets/img/menu/home.svg" alt="Home" />
                        </router-link>
                        <router-link to="/tools" class="menu-link" title='Tools'>
                            <img class="menu-link-icon" src="@/assets/img/menu/tools.svg" alt="Tools" />
                        </router-link>
                    </div>
                    <div class="menu-bottom">
                        <div class="avatar">
                            <img :src="user.avatarUrl" alt="avatar">
                        </div>
                        <div class="logout" @click="logout">
                            <img src="@/assets/img/menu/logout.svg" alt="Tools" />
                        </div>
                    </div>
                </div>
                <div class="content">
                    <router-view/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
// Api
import Server from '@/api/server'
// Components
import Notification from '@/components/shared/Notification'
import Loader from '@/components/shared/Loader'

export default {
    name: 'Main',
    components: {
        Notification, Loader
    },
    data() {
        return {
            user: null,
            state: {
                autologin: false
            }
        }
    },
    mounted() {
        Server.getAuthUser().then((response) => {
            this.user = response.data
        }).catch((_) => {
            this.state.autologin = true
            setTimeout(this.login, 1000)
        })
        // Manage main notification
        this.$root.$on("main-notify", this.onMainNotification)
    },
    beforeDestroy() {
        // Manage main notification
        this.$root.$off("main-notify", this.onMainNotification)
    },
    methods: {
        login() {
            window.location.href = '/auth/gitlab'
        },
        logout() {
            window.location.href = '/auth/logout'
        },
        onMainNotification(text, level, route = null) {
            this.$refs.notification.displayNotification(text, level, route)
        },
    }
}
</script>

<style lang="less">

@import "~@/assets/style/site.less";
@import "../node_modules/primeflex/primeflex.css";

.main {
    height: 100vh;
    width: 100%;
    background-color: #16171b;
    color: white;
    .login {
        position: relative;
        height: 100%;
        width: 100%;
        .login-card {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            .login-logo {
                img {
                    height: 100px;
                }
            }
            .login-button {
                position: relative;
                width: 250px;
                height: 50px;
                background-color: black;
                border-radius: .5em;
                padding: 7px 20px 10px 20px;
                line-height: 30px;
                text-align: center;
                color: white;
                margin: 30px auto 0 auto;
                cursor: pointer;
                &:hover {
                    background-color: @pb_red;
                }
                img {
                    height: 25px;
                    position: relative;
                    top: 6px;
                    margin-right: 10px;
                }
            }
            .loader-autologin {
                opacity: .3;
            }
        }
    }
    .main-notification {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 10px;
    }
    .page {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        .menu {
            padding: 20px;
            .menu-logo {
                padding: 10px 20px;
                margin-bottom: 10px;
                img {
                    height: 30px;
                }
            }
            .menu-card {
                background-color: #0d0c0f;
                border-radius: .5em;
                padding: 5px 15px;
                .menu-link {
                    display: block;
                    padding: 10px;
                    margin: 10px 0;
                    border-radius: .5em;
                    &:hover,
                    &.router-link-exact-active {
                        background-color: @pb_red;
                    }
                    .menu-link-icon {
                        height: 20px;
                    }
                }
            }
            .menu-bottom {
                position: absolute;
                bottom: 0;
                margin: 5px 15px 40px 15px;
                .avatar {
                    border-radius: .5em;
                    overflow: hidden;
                    img {
                        width: 40px;
                    }
                }
                .logout {
                    border-radius: .5em;
                    background-color: lighten(#16171b, 10%);
                    margin-top: 20px;
                    height: 40px;
                    width: 40px;
                    text-align: center;
                    cursor: pointer;
                    img {
                        padding-top: 8px;
                        height: 28px;
                    }
                    &:hover {
                        background-color: lighten(#16171b, 20%);
                    }
                }
            }
        }
        .content {
            position: relative;
            height: 100%;
            width: 100%;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: light;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background-color: rgba(255,255,255,0.15);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(255,255,255,0.2);
                border-radius: 10px;
            }
        }
    }
}

@media (max-width: 640px) {
    .main {
        .page {
            flex-direction: column;
            .menu {
                .menu-card {
                    display: none;
                }
                .menu-bottom {
                    bottom: auto;
                    right: 20px;
                    top: 0;
                    .avatar {
                        display: inline-block;
                        margin-top: 20px;
                        margin-right: 20px;
                        vertical-align: top;
                    }
                    .logout {
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}

</style>
