<template>
    <div class="loader">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    data() {
        return {
        }
    },
    mounted() {
    }
}
</script>

<style scoped lang="less">

@import (reference) "~@/assets/style/site.less";

</style>
