<template>
    <div class="tools">
        <div class="title">Tools</div>
        <div class="tools-card tools-crypto">
            <div class="tools-card-title">Encrypt / Decrypt</div>
            <div class="tools-card-content">
                <Crypto />
            </div>
        </div>
        <div class="tools-card tools-token">
            <div class="tools-card-title">Get Azure Token</div>
            <div class="tools-card-content">
                <TokenGenerator />
            </div>
        </div>
    </div>
</template>

<script>
// Components
import TokenGenerator from '@/components/tools/TokenGenerator'
import Crypto from '@/components/tools/Crypto'

export default {
    name: 'Tools',
    components: {
        Crypto,
        TokenGenerator
    }
}
</script>

<style lang="less">

@import (reference) "~@/assets/style/site.less";

.tools {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 70px 1fr 1fr;
    .title {
        grid-row: 1;
        grid-column: 1;
        font-size: 2em;
        font-weight: bold;
        padding: 30px 20px;
    }
    .tools-crypto {
        grid-row: 2;
        grid-column: 1;
    }
    .tools-token{
        grid-row: 3;
        grid-column: 1;
    }
    .tools-card {
        background-color: #0d0c0f;
        border-radius: 1em;
        margin: 20px;
        padding: 20px 30px 30px 30px;
        .tools-card-title {
            font-size: 1.3em;
            padding-bottom: 10px;
            font-weight: bold;
        }
        .tools-token-response {
            width: 500px;
            height: 30px;
            font-size: 0.9em;
            padding-bottom: 10px;
            font-weight: bold;
            word-wrap: break-word;
        }
        .tools-card-title img{
            display: inline;
            float:right;
            width: 20px;
        }
        input,
        select {
            width: 250px;
            padding: 5px 10px;
            margin: 5px;
            display: inline-block;
            border: 2px solid transparent;
            border-radius: 4px;
            box-sizing: border-box;
            outline: none;
            &.error {
                border: 2px solid @red_feedback;
            }
        }
    }
}

</style>
