import axios from 'axios'
axios.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 403 && !error.response.config.url.includes('auth'))
        window.location.href = '/'
    else
        return Promise.reject(error)
})

export default {

    getAuthUser() {
        return axios.get("/auth/user")
    },

    getProjects() {
        return axios.get("/api/projects")
    },
    getProjectStatusByEnv(project, env, force) {
        return axios.post("/api/project/status/" + env + "?force=" + force, project)
    },
    getProjectStatus(project, force) {
        return axios.post("/api/project/status?force=" + force, project)
    },
    getProjectTags(project) {
        return axios.post("/api/project/tags", project)
    },
    getProjectNoGo() {
        return axios.get("/api/projects/nogo")
    },
    updateProjectNoGo(projectName, payload) {
        return axios.post("/api/project/nogo", { project: projectName, payload: payload })
    },
    getServices() {
        return axios.get("/api/services")
    },
    getServiceStatus(project) {
        return axios.post("/api/service/status", project)
    },
    getServiceVersion(project, force) {
        return axios.post("/api/service/version?force=" + force, project)
    },
    getServiceVersionAvailable(project, force) {
        return axios.post("/api/service/version/available?force=" + force, project)
    },

    getCrypto(crypto, source) {
        return axios.post("/api/tools/crypto/" + source, crypto)
    },

    getUserToken(env, user) {
        return axios.post("/api/tools/getadtoken/" + env, user)
    }

}
