<template>
    <div :key="key">
        <template v-for="(notification, id) in notifications">
            <div class="notification snowsat-ripple" :class="notification.level" @click="closeNotification(id, true)" :key="id">
                <div class="notification-icon">
                    <span v-if="notification.level === 'success'" class="material-icons">check_circle</span>
                    <span v-else-if="notification.level === 'warning'" class="material-icons">error</span>
                    <span v-else-if="notification.level === 'error'" class="material-icons">error</span>
                    <span v-else-if="notification.level === 'info'" class="material-icons">info</span>
                </div>
                <div class="notification-text">
                    {{ notification.text }}
                </div>
                <div class="notification-time" :style="'width: ' + notification.timer.percent + '%'"></div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Notification",
    data() {
        return {
            key: 0,
            notifications: {}
        }
    },
    methods: {
        displayNotification(text, level) {
            let id = '_' + Math.random().toString(36).substr(2, 9)
            this.$set(this.notifications, id, {
                text: text,
                level: level,
                timer: {
                    step: 0,
                    goal: (level == "error") ? 15000 : 5000,
                    percent: 100
                }
            })
            this.notifications[id].interval = setInterval(() => {
                this.notifications[id].timer.step++
                this.notifications[id].timer.percent = 100 - ((this.notifications[id].timer.step / (this.notifications[id].timer.goal / 20)) * 100)
                if (this.notifications[id].timer.percent == 0)
                    this.closeNotification(id)
            }, 20)
        },
        closeNotification(id) {
            if (this.notifications[id].interval)
                clearInterval(this.notifications[id].interval)
            delete this.notifications[id]
            this.key++
        }
    }
}

</script>

<style scoped lang="less">

@import (reference) "../../assets/style/site.less";

.notification {
    display: block;
    width: 400px;
    margin-left: 5vw;
    border-radius: .5em;
    color: white;
    height: 45px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.15);
    &.error {
        background-color: @error !important;
    }
    &.success {
        background-color: @success !important;
    }
    &.info {
        background-color: @info !important;
    }
    &.warning {
        background-color: @warning !important;
    }
    .notification-icon {
        display: inline-block;
        vertical-align: top;
        margin: 10px;
        width: calc(40px - 20px);
    }
    .notification-text {
        display: inline-block;
        margin: 10px 10px 10px 0;
        width: calc(100% - 50px);
        line-height: 24px;
        font-size: 0.9em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &.has-link {
            width: calc(100% - 100px);
        }
    }
    .notification-link {
        display: inline-block;
        vertical-align: top;
        padding: 10px;
        padding-left: 13px;
        width: 27px;
        line-height: 24px;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .notification-time {
        position: absolute;
        bottom: 0;
        height: 5px;
        background-color: rgba(255,255,255,0.3);
    }
}

</style>
