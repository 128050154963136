<template>
    <div class="tools-token">
        <div class="tools-token-loading" v-show="state.loading">
            <div class="tools-token-loading-loader"><Loader /></div>
        </div>
        <p v-if="state.errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
                <li v-for="error in state.errors" :key="error">{{ error }}</li>
            </ul>
        </p>
        <form @submit.prevent="submitForm" class="token-form">
            <label for="userlogin">Login</label>
            <input id="userlogin" v-model="login" type="text" name="userlogin">
            <label for="userpassword">Password</label>
            <input id="userpassword" v-model="password" type="password" name="userpassword">
            <label for="environment">Environment</label>
            <select id="environment" v-model="environment">
                <option v-for="env in data.environments" v-bind:key="env.value" :value="env.value">{{ env.name }}</option>
                
            </select>
            <span></span>
            <input type="submit" class="button" value="Submit and paste to clipboard">
        </form>
        <div v-if="token.value" class="token-response">
            <img class="token-response-clipboard" @click="toClipboard()" src="@/assets/img/clipboard.svg" title="Copy">
            <span class="token-response-token">{{ token.value }}</span>
            <div class="token-response-expiration" v-if="token.expires != ''"> Token expiration: {{ token.expires.toString() }}</div>
        </div>
    </div>
</template>
<script>
//Api
import Server from "@/api/server"
// Components
import Loader from '../shared/Loader'

export default {
    name: "TokenGenerator",
    components: {
        Loader
    },
    data() {
        return {
            state: {
                loading: false,
                errors: []
            },
            token: {
                value: "",
                expires: "",
            },
            data: {
                environments: [
                    { name: "integration", value: "int"},
                    { name: "validation", value: "valid"},
                    { name: "production", value: "production"}
                ]
            },
            login: "",
            password: "",
            environment: "int",
        }
    },
    methods: {
        toClipboard() {
            navigator.clipboard.writeText(this.token.value).then(() => {
                this.$root.$emit("main-notify", "Token copied in clipboard", "success")
            })
        },
        submitForm() {
            this.state.loading = true
            this.state.errors = []
            if (this.login == "")
                this.state.errors.push("Login required.")
            if (this.password == "")
                this.state.errors.push("Password required.")
            if (this.environment == "")
                this.state.errors.push("Environment required.")
            if (this.state.errors.length > 0) {
                this.state.loading = false
                return false
            } else {
                Server.getUserToken(this.environment, {
                    login: this.login,
                    password: this.password
                }).then((response) => {
                    this.token.value = response.data["token"]
                    this.token.expires = new Date(response.data["expires"] * 1000)
                    this.toClipboard()
                }).catch((error) => {
                    console.warn("Error retrieving token", error)
                }).finally(() => {
                    this.state.loading = false
                })
            }
        }
    }
}
</script>

<style lang="less">

@import (reference) "~@/assets/style/site.less";

.tools-token {
    position: relative;
    .tools-token-loading {
        position: absolute;
        top: 0;
        height: 100%;
        width: calc(100% - 40px);
        background-color: rgba(13,12,15,0.5);
        padding-left: 280px;
        .tools-token-loading-loader {
            margin-top: -60px;
        }
    }
    .token-form {
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;
    }
    .token-response {
        margin-top: 15px;
        .token-response-token {
            display: inline-block;
            max-width: 400px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .token-response-clipboard {
            cursor: pointer;
            margin-right: 5px;
            vertical-align: top;
            opacity: .7;
            &:hover {
                opacity: 1;
            }
        }
        .token-response-expiration {
            color: @light_grey;
            font-style: italic;
        }
    }
}

</style>
