<template>
    <div class="tools-crypto">
        <div class="tools-crypto-loading" v-show="state.loading">
            <div class="tools-crypto-loading-loader"><Loader /></div>
        </div>
        <table>
            <tr><td>Value</td><td><input v-model="crypto.value" :class="{ 'error': state.error }" @input="onCryptoChange('value')"></td></tr>
            <tr><td>Development</td><td><input v-model="crypto.dev" :class="{ 'error': state.error }" @input="onCryptoChange('dev')"></td></tr>
            <tr><td>Integration</td><td><input v-model="crypto.int" :class="{ 'error': state.error }" @input="onCryptoChange('int')"></td></tr>
            <tr><td>Validation</td><td><input v-model="crypto.valid" :class="{ 'error': state.error }" @input="onCryptoChange('valid')"></td></tr>
            <tr><td>Production</td><td><input v-model="crypto.prod" :class="{ 'error': state.error }" @input="onCryptoChange('prod')"></td></tr>
        </table>
    </div>
</template>

<script>
// Api
import Server from '@/api/server'
// Components
import Loader from '../shared/Loader'

export default {
    name: 'Crypto',
    components: {
        Loader
    },
    data() {
        return {
            state: {
                loading: false,
                error: false
            },
            crypto: {
                value: null,
                dev: null,
                int: null,
                valid: null,
                prod: null
            }
        }
    },
    methods: {
        onCryptoChange(source) {
            this.state.loading = true
            Server.getCrypto(this.crypto, source).then((response) => {
                this.crypto = response.data
                this.state.error = false
            }).catch((_) => {
                this.state.error = true
            }).finally(() => {
                this.state.loading = false
            })
        }
    }
}
</script>

<style scoped lang="less">

@import (reference) "~@/assets/style/site.less";

.tools-crypto {
    position: relative;
    .tools-crypto-loading {
        position: absolute;
        top: 0;
        height: 100%;
        width: calc(100% - 40px);
        background-color: rgba(13,12,15,0.5);
        padding-left: 280px;
        .tools-crypto-loading-loader {
            margin-top: -60px;
        }
    }
}

</style>
