import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../apps/Home.vue'
import Tools from '../apps/Tools.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/tools', name: 'Tools', component: Tools }
]

const router = new VueRouter({
  routes
})

export default router
