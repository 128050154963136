<template>
    <div class="no-go">
        <div class="no-go-header">
            <div class="project-info">
                {{ project.name }}
            </div>
        </div>
        <div class="no-go-content">
            <!-- Rounded switch-->
            <div class="nogo-message"  >
                <div :style="'display: flex;'">
                    <span class="no-go-why">Why we can not deliver application:</span>
                    <div v-if="!firstEntry" class="no-go-update-text">
                        <Slider v-model="isChecked" label="Update text" textPosition="left" />
                    </div>
                </div>
                <textarea v-if="isChecked" class="nogo-message-textarea" v-model="noGoMessage" placeholder="add text description"></textarea>
                <div v-if="!isChecked" class="nogo-message-readonly" v-html="noGoMessageBrTags"></div>
            </div>

            <div v-if="(userDisplayName || userName) && !isChecked" class="user-info">
                <span v-if="userDisplayName">Set by: {{ userDisplayName }}</span>
                <span v-if="userName"> ({{ userName }})</span>
                <span v-if="time"> on {{ time }}</span>
            </div>
        </div>

        <div class="nogo-button">
            <div v-if="isChecked" class="nogo-button-style enable-nogo" :class="{ 'disabled': noGoMessage.length === 0 }" @click="onValid">{{ noGoButtonText }}</div>
            <div v-if="!isChecked" class="nogo-button-style disable-nogo" @click="onValid">Disable NoGo</div>
            <div class="nogo-button-style" @click="onCancel">Cancel</div>
        </div>

    </div>
</template>

<script>
import Slider from '@/components/shared/Slider'

export default {
    name: 'NoGo',
    components: {
        Slider
    },
    props: {
        project: { type: Object, default: null },
        projects: { type: Array, default: null },
        user: { type: Object, default: null }
    },
    data() {
        return {
            firstEntry: true,
            isChecked: false, // Initialize the checkbox state to unchecked
            noGoMessage: '',
            userName: null,
            userDisplayName: null,
            time: null
        }
    },
    computed: {
        noGoButtonText() {
            return this.firstEntry ? 'Set to NoGo' : 'Update text description'
        },
        noGoMessageBrTags() {
            return this.noGoMessage.replace(/\n/g, "<br>")
        }
    },
    mounted() {
        let msg = this.projects.find((item) => item.project === this.project.name)
        if(msg) {
            this.firstEntry = false
            this.noGoMessage = msg.info.nogoMessage
            this.userDisplayName = msg.info.userDisplayName
            this.userName = msg.info.userName
            this.time = new Date(msg.info.time).toISOString()
        }
        else {
            // No message, so user would probably set one
            this.isChecked = true
        }
    },
    methods: {
        onValid() {
            if ((this.noGoMessage.length !== 0) || !this.isChecked) {
                let payload = {
                    project: this.project.name,
                    nogo: this.isChecked,
                    nogoMessage: this.isChecked ? this.noGoMessage: null,
                    userName: this.user.username,
                    userDisplayName: this.user.displayName,
                    time: Date.now()
                }
                this.$emit('valid', payload)
            }
        },
        onCancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style scoped lang="less">

@import (reference) "~@/assets/style/site.less";
.no-go {
    width: 50dvw;
    height: 50dvh;
    color: @white;
    .no-go-header {
        height:10dvh;
    }
    .no-go-content {
        padding: 10px;
        position: relative;
        height: 32dvh;
        .no-go-why {
            margin-right: auto;
        }
        .no-go-update-text {
            display: flex;
            align-items: center;
            .item-update-text {
                margin-right: 10px;
            }
        }
        .nogo-message {
            width: 100%;
            .nogo-message-textarea {
                margin-top: 5px;
                width: 100%;
                height: 22dvh;
                resize: none;
            }
            textarea {
                padding: 5px;
                background-color: lighten(#16171b, 30%);
                color: @white;
                border-radius: .5em;
                border: none;
            }
            .not-active {
                opacity: .4;
                text-decoration: line-through;
            }
            .nogo-message-readonly {
                margin-top: 5px;
                padding: 10px;
                border-radius: .5em;
                background-color: lighten(#16171b, 10%);
            }
        }
        .user-info {
            font-style: italic;
            position: absolute;
            bottom: 10px;
        }
    }
    .nogo-button {
        height: 8dvh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .nogo-button-style {
            padding: 10px;
            border-radius: 0.4em;
            background-color: grey;
            color: white;
            cursor: pointer;
            &:hover {
                filter: brightness(1.1);
            }
            &.enable-nogo {
                background-color: #d22925;
            }
            &.disable-nogo {
                background-color: #01bd4f;
            }
            &.disabled {
                cursor: not-allowed;
                opacity: .5;
                filter: brightness(1);
            }
        }
    }
    .project-info {
        padding: 5px;
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        color: @grey;
    }
}
</style>
